import { useCallback } from 'react';
import { useStateReducer } from '_Hooks';

import PasswordResetPanel from './PasswordResetPanel';
import { INPUT_TYPE_PASSWORD } from './Utils';

const PasswordResetForm = (props) => {
  const { error: propsError, messages, locale, dir, email, reset_password_token, invitation_token, return_to } = props;
  const [state, setState] = useStateReducer(
    () => ({
      loading: false,
      inputTypePassword: INPUT_TYPE_PASSWORD,
      inputTypePasswordConfirm: INPUT_TYPE_PASSWORD,
      password,
      password_confirmation,
      error: propsError,
    })
  );
  const {
    loading,
    inputTypePassword,
    inputTypePasswordConfirm,
    password,
    password_confirmation,
    error
  } = state;

  const onPasswordInputChange = useCallback(
    password => setState({ password }),
    [setState]
  );

  const onPasswordConfirmInputChange = useCallback(
    password_confirmation => setState({ password_confirmation }),
    [setState]
  );

  return (
    <PasswordResetPanel
      loading={loading}
      messages={messages}
      locale={locale}
      dir={dir}
      inputTypePassword={inputTypePassword}
      inputTypePasswordConfirm={inputTypePasswordConfirm}
      onChangePassword={onPasswordInputChange}
      onChangePasswordConfirm={onPasswordConfirmInputChange}
      email={email}
      reset_password_token={reset_password_token}
      invitation_token={invitation_token}
      password={password}
      password_confirmation={password_confirmation}
      error={error}
      setState={setState}
      return_to={return_to}
    />
  );
};

export default PasswordResetForm;
