import { useCallback } from 'react';
import { Column, ColumnItem, Row, RowItem } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGError, SVGExclamationSolid, SVGTickCircle } from '_Icons/main';
import { SVGLogout } from '_Icons/menu';
import { H, HEADING_SIZES, P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { Button, BUTTON_THEME } from '_Elements/button/v2';

import EmailPassCodeBox from '../login/EmailPassCodeBox';
import PasswordBox from '../login/PasswordBox';
import { INPUT_TYPE_PASSWORD, INPUT_TYPE_TEXT, resetPassword, setPassword } from './Utils';

const ONE_LOWERCASE_LETTER = /[a-z]/;
const ONE_UPPERCASE_LETTER = /[A-Z]/;
const ONE_SYMBOL = /[@$!%*#?&]/;
const ONE_NUMBER = /\d/;
const TEN_CHARACTERS = /.{10,}/;

const validFormat = password =>
  ONE_LOWERCASE_LETTER.test(password) &&
  ONE_UPPERCASE_LETTER.test(password) &&
  ONE_SYMBOL.test(password) &&
  ONE_NUMBER.test(password);

const validLength = password =>
  TEN_CHARACTERS.test(password);

const ValidatorResult = props => {
  const { password='', regexp, message } = props;
  const valid = regexp.test(password);
  return (
    <ColumnItem>
      <Row>
        <RowItem style={{ marginInlineEnd: 4 }}>
          {valid ? (
            <SVGTickCircle size={12} color={SYSTEM_COLOR.CHART.GREEN} />
          ) : (
            <SVGError size={12} color={SYSTEM_COLOR.CHART.RED} />
          )}
        </RowItem>
        <RowItem>
          <P textSize={PARAGRAPH_SIZES.P4}>{message}</P>
        </RowItem>
      </Row>
    </ColumnItem>
  );
};

const PasswordResetPanel = (props) => {
  const {
    loading,
    messages,
    dir,
    locale,
    inputTypePassword,
    inputTypePasswordConfirm,
    onChangePassword,
    onChangePasswordConfirm,
    email,
    reset_password_token,
    invitation_token,
    password,
    password_confirmation,
    error: propsError,
    setState,
    return_to,
  } = props;

  const onPasswordIconClick = useCallback(
    () => setState({ inputTypePassword: inputTypePassword == INPUT_TYPE_PASSWORD ? INPUT_TYPE_TEXT : INPUT_TYPE_PASSWORD }),
    [setState, inputTypePassword]
  );

  const onPasswordConfirmIconClick = useCallback(
    () => setState({ inputTypePasswordConfirm: inputTypePasswordConfirm == INPUT_TYPE_PASSWORD ? INPUT_TYPE_TEXT : INPUT_TYPE_PASSWORD }),
    [setState, inputTypePasswordConfirm]
  );

  const setUserPassword = useCallback(
    async ({ email, invitation_token, password, password_confirmation, locale }) => {
      try {
        const data = await setPassword({ email, invitation_token, password, password_confirmation, locale, return_to });
        const { status, error } = data;

        if (status != 200) return { error: error || messages['unknown'] };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const resetUserPassword = useCallback(
    async ({ email, reset_password_token, password, password_confirmation, locale }) => {
      try {
        const data = await resetPassword({ email, reset_password_token, password, password_confirmation, locale, return_to });
        const { status, error } = data;

        if (status != 200) return { error: error || messages['unknown'] };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const onClick = useCallback(
    async () => {
      if (!password) return setState({ error: messages['field_not_supplied'] });

      setState({ error: undefined, loading: true });

      let validationError;
      if (password && !validationError && !validFormat(password)) validationError = messages['invalid_password_format'];
      if (password && !validationError && !validLength(password)) validationError = messages['invalid_password_length'];
      if (password && !validationError && password != password_confirmation) validationError = messages['passwords_must_match'];

      if (validationError)
        return setState({ error: validationError, loading: false });

      const { error: setPasswordError, location } = reset_password_token
        ? await resetUserPassword({ reset_password_token, password, password_confirmation, locale, return_to })
        : await setUserPassword({ invitation_token, password, password_confirmation, locale, return_to });

      if (setPasswordError) return setState({ error: setPasswordError, loading: false });
      metrics.track('Sign in - Forgot password - Password updated');
      return window.location.href = location;
    },
    [setState, reset_password_token, password, password_confirmation]
  );

  return (
    <div className={'login-portal__input'} data-component={'password-panel'}>
      <Row center>
        <SVGLogo
          size={64}
        />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5}>{messages['create_a_new_password']}</H>
      </Row>
      <Row style={{ marginBlockStart: 40 }}>
        <P textSize={PARAGRAPH_SIZES.P3} style={{ marginBlockEnd: 4 }}>{messages['enter_your_email']}</P>
      </Row>
      <EmailPassCodeBox
        dir={dir}
        email={email}
        disabled={true}
      />
      <Row style={{ marginBlockStart: 24 }}>
        <P textSize={PARAGRAPH_SIZES.P3} style={{ marginBlockEnd: 4 }}>{messages['enter_new_password']}</P>
      </Row>
      <PasswordBox
        dir={dir}
        inputType={inputTypePassword}
        password={password}
        onChange={onChangePassword}
        onIconClick={onPasswordIconClick}
      />
      <Row style={{ marginBlockStart: 24 }}>
        <P textSize={PARAGRAPH_SIZES.P3} style={{ marginBlockEnd: 4 }}>{messages['confirm_new_password']}</P>
      </Row>
      <PasswordBox
        dir={dir}
        inputType={inputTypePasswordConfirm}
        password={password_confirmation}
        onChange={onChangePasswordConfirm}
        onIconClick={onPasswordConfirmIconClick}
      />
      <Row style={{ alignItems: 'start', marginBlockStart: 4, minHeight: 42 }}>
        {propsError && (
          <>
            <SVGExclamationSolid size={16} color={SYSTEM_COLOR.CHART.RED} />
            <P textSize={PARAGRAPH_SIZES.P3} style={{ marginInlineStart: 4, color: SYSTEM_COLOR.CHART.RED }}>{propsError}</P>
          </>
        )}
      </Row>
      <Row alignItems={'flex-start'} style={{ marginBlockStart: 12 }}>
        <RowItem>
          <Column spacing={4}>
            <ColumnItem>
              <P textSize={PARAGRAPH_SIZES.P4} style={{ marginBottom: 16 }}>{messages['your_password_must_have']}</P>
            </ColumnItem>
            <ValidatorResult message={messages['one_lowercase_letter']} password={password} regexp={ONE_LOWERCASE_LETTER} />
            <ValidatorResult message={messages['one_uppercase_letter']} password={password} regexp={ONE_UPPERCASE_LETTER} />
            <ValidatorResult message={messages['one_symbol']} password={password} regexp={ONE_SYMBOL} />
            <ValidatorResult message={messages['one_number']} password={password} regexp={ONE_NUMBER} />
            <ValidatorResult message={messages['ten_characters']} password={password} regexp={TEN_CHARACTERS} />
          </Column>
        </RowItem>
        <RowItem>
          <Button
            trailingIcon={<SVGLogout size={22} />}
            rounded
            theme={BUTTON_THEME.PRIMARY}
            onClick={onClick}
            loading={loading}
          >
            {messages['create']}
          </Button>
        </RowItem>
      </Row>
    </div>
  );
};

export default PasswordResetPanel;
