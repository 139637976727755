import { useCallback } from 'react';
import { useStateReducer } from '_Hooks';

import { Row } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGNext } from '_Icons/main';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { Button, BUTTON_THEME } from '_Elements/button/v2';

const PasswordResetExpired = props => {
  const { location, messages, dir } = props;
  const [state, setState] = useStateReducer(
    () => ({
      loading: false,
    })
  );
  const { loading } = state;

  const onClick = useCallback(
    () => {
      setState({ loading: true });
      return window.location.href = location;
    },
    [setState, location]
  );

  return (
    <div className={'password-reset-expired-portal__input'} data-component={'email-pass-code-panel'} dir={dir}>
      <Row center style={{ color: SYSTEM_COLOR.PRIMARY, justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <span style={{ width: 14, visibility: 'hidden' }} />
        <SVGLogo
          size={64}
        />
        <span style={{ width: 14, visibility: 'hidden' }} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5} style={{ textAlign: 'center' }}>{messages['token_expired']}</H>
      </Row>
      <Row style={{ marginBlockStart: 16 }}>
        <P textSize={PARAGRAPH_SIZES.P2} style={{ textAlign: 'center' }}>{messages['please_return_to_login']}</P>
      </Row>
      <Row center style={{ marginBlockStart: 28 }}>
        <Button
          trailingIcon={<SVGNext size={22} />}
          rounded
          theme={BUTTON_THEME.PRIMARY}
          onClick={onClick}
          loading={loading}
        >
          {messages['go_to_log_in']}
        </Button>
      </Row>
    </div>
  );
};

export default PasswordResetExpired;
