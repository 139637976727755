export const INPUT_TYPE_PASSWORD = 'password';
export const INPUT_TYPE_TEXT = 'text';

export const setPassword = async ({ email, invitation_token, password, password_confirmation, locale, return_to }) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const response = await fetch(
    '/users/invitation',
    {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user: { email, invitation_token, password, password_confirmation }, locale, return_to }),
    }
  );

  const data = await response.json();
  const status = response.status;

  return { ...data, status };
};

export const resetPassword = async ({ email, reset_password_token, password, password_confirmation, locale, return_to }) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const response = await fetch(
    '/users/password',
    {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user: { email, reset_password_token, password, password_confirmation }, locale, return_to }),
    }
  );

  const data = await response.json();
  const status = response.status;

  return { ...data, status };
};
